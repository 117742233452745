<template>
<div class="about">
<div class="post-image"  style="background: url('http://printek.top-it72.ru/wp-content/uploads/2021/02/shirokoformat.jpg') center center / cover no-repeat fixed;">
        <div class="slide-overlay post-overlay">
            <div class="post-title">О компании</div>
        </div>

    </div>
    <section>
      <div class="container">
        <div class="about-info" v-html="about">
          
        </div>
      </div>
    </section>

    
    <contacts />
</div>
</template>

<script>
import Contacts from '../components/Contacts.vue'
export default {
    data() {
      return {
        about: this.$store.state.about
      }
    },
    components: { Contacts },
    mounted() {
       document.title = 'О компании | UVprint'
      scrollTo(0,0)
    },
    
}
</script>

<style>
.about li{
  list-style: circle;
}
.about ul{
  padding: 20px 30px;
}
.about{
  font-size: 18px;
}
.post-image {
    
    position: relative;
}
.post-title {
    color: white;
    font-weight: 600;
    font-size: 50px;
    text-transform: uppercase;

}

.post-overlay {

    margin-top: 85px;

}
</style>
